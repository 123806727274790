`<template>
<b-card class="w-100 mb-4" no-body>
    <b-card-body>
        <b-form>
                        <b-form-group>
                          <b-form-input
                            id="txt-fullname"
                            type="text"
                            placeholder="Enter fullname"
                            class="mb-3"
                            v-model="form.full_name"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-fullname"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-email"
                            type="text"
                            placeholder="Enter Email"
                            class="mb-3"
                            v-model="form.email"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-email"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-phone"
                            type="text"
                            placeholder="Enter Phone"
                            class="mb-3"
                            v-model="form.phone"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-email"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <b-form-group>
                          <b-form-input
                            id="txt-password"
                            type="password"
                            placeholder="Enter Password"
                            class="mb-3"
                            v-model="form.password"
                          ></b-form-input>
                          <b-form-invalid-feedback id="txt-password"
                            >This is a required field</b-form-invalid-feedback
                          >
                        </b-form-group>

                        <b-form-group>
                          <b-form-select
                            class="mb-3"
                            placeholder="Please Select"
                            v-model="form.user_type"
                            :options="userTypes"
                          ></b-form-select>
                        </b-form-group>
                        <b-button pill variant="primary" class="px-4" :disabled="$v.form.$invalid" @click="updateUse">Save</b-button>
                      </b-form>
    </b-card-body>
</b-card>
</template>

<script>

import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  email,
  maxLength,
} from "vuelidate/lib/validators";

export default {
    name: "Add_User",
    mixins: [validationMixin],
  data: () => ({
    form: {
      full_name: null,
      password: null,
      email: null,
      phone: null,
      user_type: null
    },
    error: false,
    errorMsg: null,
    checkbox: false,
    userTypes: [
      { text: "Admin", value: 1 },
      { text: "Vendor", value: 2 },
      { text: "Service Provider", value: 3 },
      { text: "Customer", value: 4 }
    ]
  }),
  computed: {},
  mounted() {
        this.initComp();
    },
  validations: {
    form: {
      full_name: {
        required,
        minLength: minLength(4),
      },
      email: {
        required,
        email,
        maxLength: maxLength(20),
      },
      password: {
        required,
        minLength: minLength(8),
      },
      phone: {
        required,
        minLength: minLength(8),
      },
      user_type: {
        required
      }
    },
  },
  methods: {
    ...mapActions(["getUser","updateUser"]),
    initComp(){
      this.getUser({id: this.$route.params.id}).then((res)=>{
        this.form = res[0];
      })
    },
    validateState(full_name) {
      const { $dirty, $error } = this.$v.form[full_name];
      return $dirty ? !$error : null;
    },
    register(){
      this.registerMe(this.form).then((res) => {
        if(res && 'status' in res && res.status >= 400){
          this.$bvToast.toast(res.data, {
            title: "Something Went Wrong",
            variant: 'danger',
            solid: true
          })
        }
        else{
          this.$bvToast.toast(res.data, {
            title: "Success",
            variant: 'success',
            solid: true
          })
        }
      })
    },
    updateUse(){
            this.updateUser(this.form).then(res => {
                if('status' in res && res.status >= 400){
                    this.errorMsg = res.data;
                    this.error = true;
                }
                else{
                    this.successMsg = res;
                    this.success = true;
                    this.$bvToast.toast('Toast body content', {
                        title: "User Updated Successfully",
                        variant: 'success',
                        solid: true
                    })
                }
            })
        }
  },
};
</script>
